export interface ElectronicDefinedSettingsDto {
  useNationalIdSettings: boolean,
  blockNationalIdError: boolean,
  displayNationalIdWarningMessage: boolean
}

export enum ElectronicTagSettingsStatus {
  BlockNationalIdError = 1,
  DisplayNationalIdWarningMessage = 2
}

export interface ElectronicDefinedSettingsFormDto {
  useNationalIdSettings: boolean,
  selectedSettingOption: number | null
}
