import { Injectable } from '@angular/core';
import { CommonDialogSize, HashMap } from '@common/angular/interfaces';
import { TranslocoService } from '@jsverse/transloco';
import { Confirmation } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { merge, Observable, take } from 'rxjs';

import {
  CommonConfirmationDialogComponent,
  CommonDefaultDialogComponent,
  CommonDeleteConfirmationDialogComponent,
  CommonErrorDialogComponent,
  CommonInformationDialogComponent
} from '../components';
import { ConfirmationModalConfig, ErrorModalConfig } from '../interfaces';

@Injectable()
export class AhCommonDialogService {

  state: HashMap = {};

  constructor(
    private dialogService: DialogService,
    private translateService: TranslocoService
  ) {}

  openModal(component: any, config?: DynamicDialogConfig, size = CommonDialogSize.MD): DynamicDialogRef {
    return this.openGenericModal(component, config || {}, size);
  }

  openConfirmationModal(config: ConfirmationModalConfig): Observable<boolean> {
    const genericModalConfig = this.transformModalConfig({
      ...config,
      acceptLabel: config.acceptLabel || this.getTranslation('dialog.yes'),
      rejectLabel: config.rejectLabel || this.getTranslation('dialog.cancel')
    });
    const dialogRef = this.openGenericModal(CommonConfirmationDialogComponent, genericModalConfig);
    return this.getDialogCloseEvent(dialogRef);
  }

  openDefaultModal(config: ConfirmationModalConfig): Observable<boolean> {
    const genericModalConfig = this.transformModalConfig({
      ...config,
      acceptLabel: config.acceptLabel || this.getTranslation('dialog.yes'),
      rejectLabel: config.rejectLabel || this.getTranslation('dialog.cancel')
    });
    const dialogRef = this.openGenericModal(CommonDefaultDialogComponent, genericModalConfig);
    return this.getDialogCloseEvent(dialogRef);
  }

  openInfoModal(message: string, header?: string, closeLabel?: string): Observable<boolean> {
    closeLabel ||= this.getTranslation('dialog.close')
    const modalConfig = this.getDefaultInformationModalConfig(message, header || '', closeLabel);
    const dialogRef = this.openGenericModal(CommonInformationDialogComponent, modalConfig, CommonDialogSize.SM);
    return this.getDialogCloseEvent(dialogRef);
  }

  closeAll(): void {
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }

  openErrorModal(config: ErrorModalConfig): void {
    const genericModalConfig = this.transformModalConfig({
      ...config,
      acceptLabel: config?.acceptLabel || this.getTranslation('dialog.ok')
    });
    this.openGenericModal(CommonErrorDialogComponent, genericModalConfig);
  }

  openDeleteConfirmationModal(config: Confirmation): void {
    const genericModalConfig = this.transformModalConfig({
      ...config,
      header: config.header || this.getTranslation('dialog.delete-confirmation.header'),
      message: config.message || this.getTranslation('dialog.delete-confirmation.message'),
      acceptLabel: config.acceptLabel || this.getTranslation('dialog.delete-confirmation.delete'),
      rejectLabel: config.rejectLabel || this.getTranslation('dialog.cancel')
    });
    this.openGenericModal(CommonDeleteConfirmationDialogComponent, genericModalConfig);
  }

  private openGenericModal(component: any, config: DynamicDialogConfig, size = CommonDialogSize.MD): DynamicDialogRef {
    return this.dialogService.open(component, {
      ...config,
      width: config.width ?? this.getModalWidth(size),
      height: size === CommonDialogSize.FS ? '90vh' : config.height
    });
  }

  private transformModalConfig(config?: Confirmation): DynamicDialogConfig {
    if (!config) return {};

    return {
      data: {
        ...config
      },
      header: config.header,
      styleClass: 'common-confirmation-modal',
      closeOnEscape: config.closeOnEscape,
      duplicate: true
    }
  }

  private getModalWidth(width: CommonDialogSize): string {
    switch (width) {
      case CommonDialogSize.SM:
        return '400px';
      case CommonDialogSize.MD:
        return '600px'
      case CommonDialogSize.LG:
        return '800px'
      case CommonDialogSize.XL:
        return '1140px'
      case CommonDialogSize.FS:
        return '90vw'
      default:
        return '400px'
    }
  }

  private getDialogCloseEvent(dialogRef: DynamicDialogRef): Observable<boolean> {
    return merge(
      dialogRef.onClose,
      dialogRef.onDestroy
    ).pipe(take(1));
  }

  private getDefaultInformationModalConfig(message: string, header: string, closeLabel: string): DynamicDialogConfig {
    return {
      data: { message, closeLabel },
      styleClass: 'common-information-modal',
      duplicate: true,
      header
    }
  }

  private getTranslation(key: string, params?: HashMap): string {
    return this.translateService.translate(key, params);
  }

}
