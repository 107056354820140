export enum MigrationStatus {
  Pending = 1,
  InProgress = 2,
  Completed = 3,
  Failed = 4,
  Canceled = 5,
  Deleting = 6,
  Deleted = 7
}

export const MigrationStatusLabels: Record<MigrationStatus, string> = {
  [MigrationStatus.Pending]: 'Pending',
  [MigrationStatus.InProgress]: 'In Progress',
  [MigrationStatus.Completed]: 'Completed',
  [MigrationStatus.Failed]: 'Failed',
  [MigrationStatus.Canceled]: 'Canceled',
  [MigrationStatus.Deleting]: 'Deleting',
  [MigrationStatus.Deleted]: 'Deleted'
};

export function getMigrationStatusLabel(status: MigrationStatus): string {
  return MigrationStatusLabels[status];
}
